import useIsMobileDevice from "../../hooks/useIsMobileDevice";

interface NeutralHappyIconProps {
    active: boolean;
    isPDF?: boolean;
}
const NeutralHappyIcon: React.FC<NeutralHappyIconProps> = ({active = false, isPDF=false}) => {
    const isMobile = useIsMobileDevice();
    const iconSize = isMobile ? 12 : isPDF ? 24 : 16;
    
    const svgIcon = 
    active ? (`
    <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="8" fill="#89DC4E"/>
    <ellipse cx="5.8459" cy="5.64144" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
    <ellipse cx="10.3586" cy="5.64144" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
    <path d="M4.92285 9.11816C4.92285 10.155 5.65057 11.796 8.10234 11.796C10.5541 11.796 11.0767 10.0525 11.0767 9.11816" stroke="#2A4D06" stroke-width="1.29182"/>
  </svg>`
 ) : (
 `<svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
 <g opacity="0.4">
   <rect width="16" height="16" rx="8" fill="#89DC4E"/>
   <ellipse cx="5.8459" cy="5.64144" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
   <ellipse cx="10.3586" cy="5.64144" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
   <path d="M4.92285 9.11816C4.92285 10.155 5.65057 11.796 8.10234 11.796C10.5541 11.796 11.0767 10.0525 11.0767 9.11816" stroke="#2A4D06" stroke-width="1.29182"/>
 </g>
</svg>`
)
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default NeutralHappyIcon;
  

