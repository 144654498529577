import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Wrapper from "../common/wrapper/Wrapper";
import StarIcon from "../icons/StarIcon";
import FeedbackAnalysisLine from "../feedbackAnalycicLine/FeedbackAnalysis";
import { useEffect, useState } from "react";

interface StarsData {
  Count: number;
  Star: number;
}

interface Props {
  data: StarsData[];
}

const FeedbackAnalysis: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const [star1, setStar1] = useState({ Count: 0, Star: 0 });
  const [star2, setStar2] = useState({ Count: 0, Star: 0 });
  const [star3, setStar3] = useState({ Count: 0, Star: 0 });
  const [star4, setStar4] = useState({ Count: 0, Star: 0 });
  const [star5, setStar5] = useState({ Count: 0, Star: 0 });

  useEffect(() => {
    if (data.length > 0) {
      const calculateWeightedAverage = (
        data: StarsData[],
      ): { Count: number; Star: number }[] => {
        const groupedData: {
          [key: number]: { Count: number; WeightedSum: number };
        } = {};

        data.forEach((item) => {
          let category;
          if (item.Star <= 1.5) {
            category = 1;
          } else if (item.Star <= 2.5) {
            category = 2;
          } else if (item.Star <= 3.5) {
            category = 3;
          } else if (item.Star <= 4.5) {
            category = 4;
          } else {
            category = 5;
          }

          groupedData[category] = groupedData[category] || {
            Count: 0,
            WeightedSum: 0,
          };
          groupedData[category].Count += item.Count;
          groupedData[category].WeightedSum += item.Count * item.Star;
        });

        // Create star categories based on the grouped data
        const starCategories = Array.from({ length: 5 }, (_, index) => {
          const categoryData = groupedData[index + 1] || {
            Count: 0,
            WeightedSum: 0,
          };
          let star =
            categoryData.Count !== 0
              ? categoryData.WeightedSum / categoryData.Count
              : 0;
          // Conditionally round based on the decimal part
          const decimalPart = star % 1;
          if (decimalPart < 0.5) {
            star = Math.floor(star);
          } else if (decimalPart > 0.5) {
            star = Math.ceil(star);
          }
          return {
            Count: categoryData.Count,
            Star: star,
          };
        });

        return starCategories;
      };

      const customRound = (num: number) => {
        const decimalPart = num % 1;
        if (decimalPart < 0.5) {
          return Math.floor(num);
        } else if (decimalPart > 0.5) {
          return Math.ceil(num);
        } else {
          return num;
        }
      };

      const starCategories = calculateWeightedAverage(data);
      setStar1({
        Count: starCategories[0].Count,
        Star: customRound(starCategories[0].Star),
      });
      setStar2({
        Count: starCategories[1].Count,
        Star: customRound(starCategories[1].Star),
      });
      setStar3({
        Count: starCategories[2].Count,
        Star: customRound(starCategories[2].Star),
      });
      setStar4({
        Count: starCategories[3].Count,
        Star: customRound(starCategories[3].Star),
      });
      setStar5({
        Count: starCategories[4].Count,
        Star: customRound(starCategories[4].Star),
      });
    } else {
      setStar1({ Count: 0, Star: 0 });
      setStar2({ Count: 0, Star: 0 });
      setStar3({ Count: 0, Star: 0 });
      setStar4({ Count: 0, Star: 0 });
      setStar5({ Count: 0, Star: 0 });
    }
  }, [data]);

  const totalCount = data.reduce((acc, curr) => acc + curr.Count, 0);
  const totalStars = data.reduce(
    (acc, curr) => acc + curr.Star * curr.Count,
    0,
  );

  const averageStars = totalCount > 0 ? totalStars / totalCount : 0;

  return (
    <Wrapper
      onClick={() => {}}
      classes="bg-white rounded-lg px-4 py-2 md:px-12 md:py-7 min-w-full flex flex-col shadow-custom3 pb-2.5 md:h-full"
    >
      <div className="flex items-center justify-between">

      <Heading
        children={t("feedbackAnalysis.title")}
        align=""
        classes="black text-left"
        color=""
        size="h1"
        />
        <a href="/reports/feedbackReport" className="flex items-center text-[#AC63E9] text-xs">{t("feedbackAnalysis.viewFullReport")}<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 12L10 8L6 4" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</a>
        </div>
      <div className="flex items-center justify-between w-3/5 py-5">
        <span className="flex items-center text-gray-400">
          <StarIcon />
          <Heading
            children={averageStars.toFixed(1)}
            align=""
            classes="text-black text-left ml-2 mr-2"
            color=""
            size="h1"
          />
          ({totalCount})
        </span>
      </div>

      <FeedbackAnalysisLine
        feedbackRating={star1.Star}
        sum={star1.Count}
        text={`1${t("feedbackAnalysis.star")}`}
      />
      <FeedbackAnalysisLine
        feedbackRating={star2.Star}
        sum={star2.Count}
        text={`2${t("feedbackAnalysis.stars")}`}
      />
      <FeedbackAnalysisLine
        feedbackRating={star3.Star}
        sum={star3.Count}
        text={`3${t("feedbackAnalysis.stars")}`}
      />
      <FeedbackAnalysisLine
        feedbackRating={star4.Star}
        sum={star4.Count}
        text={`4${t("feedbackAnalysis.stars")}`}
      />
      <FeedbackAnalysisLine
        feedbackRating={star5.Star}
        sum={star5.Count}
        text={`5${t("feedbackAnalysis.stars")}`}
      />
    </Wrapper>
  );
};

export default FeedbackAnalysis;
