import { useEffect, useState } from "react";
import Wrapper from "../wrapper/Wrapper";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

  
interface SimpleBarChartProps {
  data: Array<Record<string, any>>;
  monthBarKeys?: any;
  barKeys?: any[];
  xKey: string;
  colors?: any;
  isMonthChart?: boolean;
  isYearChart?: boolean;
  isWeekChart?: boolean;
  isTableSalesChart?: boolean;
  isFlorChart?: boolean;
  isForPdf?: boolean;
}

const SimpleBarChart: React.FC<SimpleBarChartProps> = ({ data, barKeys, xKey, colors, isMonthChart, isYearChart, isWeekChart, isTableSalesChart, isFlorChart, isForPdf }) => {
  const [visibleBars, setVisibleBars] = useState<Record<string, boolean>>(() => {
    if (barKeys) {
      return barKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {});
    }
    return {};
  });
  
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<string | null>(currentYear.toString());
  const uniqueYears = Array.from(new Set(data.map((item) => item.Year))).filter(Boolean) as string[];
  const filteredData = selectedYear
  ? data.filter((item) => item.Year === selectedYear)
  : data;
  const closestYear =
    uniqueYears.length > 0
      ? uniqueYears.reduce((prev, curr) =>
          Math.abs(parseInt(curr) - currentYear) < Math.abs(parseInt(prev) - currentYear) ? curr : prev
        )
      : null;
      const yearColors = uniqueYears.reduce((acc, year) => {
        acc[year] = year === closestYear ? "#b732cc" : "#f07cab";
        return acc;
      }, {} as Record<string, string>);
  
useEffect(() => {
  if (barKeys) {
    setVisibleBars(
      barKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {})
    );
  }
}, [barKeys]);

  const handleLegendClick = (data: { value: string }) => {
    if (isMonthChart || isWeekChart ) {
      const { value } = data;
      setVisibleBars((prev) => ({ ...prev, [value]: !prev[value] }));
    } else {
      const { value } = data;
      if (selectedYear === value) {
        setSelectedYear(selectedYear);
      } else {
        setSelectedYear(value);
      }
    }
  };


  return (
    <Wrapper onClick={() => {}} classes=" w-full">
      <ResponsiveContainer width="100%" height={isForPdf ?  208 : 400}>
        <BarChart
        layout={(isMonthChart || isYearChart || isWeekChart) ? "horizontal" : "vertical"}
          data={(isTableSalesChart || isFlorChart) ? filteredData : data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        > 
          <CartesianGrid strokeDasharray="0" vertical={(isMonthChart || isYearChart || isWeekChart) ? false : true} horizontal={(isMonthChart || isYearChart || isWeekChart) ? true : false}/>
          {isMonthChart && 
          <>
          <XAxis dataKey={xKey} /> 
          <YAxis />
          </>
 }
          {isYearChart && <XAxis dataKey="Year" />}
          {isWeekChart &&     
           <>
          <XAxis dataKey={xKey} /> 
          <YAxis />
          </>}
          {isTableSalesChart && (
          <>
          <XAxis type="number" />
          <YAxis type="category" dataKey="TableName" />
          </>
          )}
          {isFlorChart &&      
          <>
          <XAxis type="number" />
          <YAxis type="category" dataKey="FloorName" />
          </>
          }
          <Tooltip />
  {isMonthChart && barKeys && visibleBars && (
    <Legend
        onClick={handleLegendClick}
        payload={barKeys.map((month) => ({
            id: month,
            value: month,
            type: "circle",
            color: visibleBars[month] ? (colors?.[month] || "#A9A9A9") : "#D3D3D3", // Gray if invisible
        }))}
    />
)}
          
    
{isWeekChart && barKeys && (
    <Legend
        onClick={handleLegendClick}
        payload={barKeys.map((week) => ({
            id: week,
            value: week,
            type: "circle",
            color: visibleBars[week] ? (colors?.[week] || "#A9A9A9") : "#D3D3D3", // Gray if invisible
        }))}
    />
)}
         {isTableSalesChart && (
          <Legend
            onClick={handleLegendClick}
            payload={uniqueYears.map((year) => ({
              id: year,
              value: year,
              type: "circle",
              color: selectedYear === year ? colors?.[year] || "#A9A9A9" : "#D3D3D3", // Selected year gets its color, others are gray
            }))}
          />
        )}
        
        {isFlorChart && (
  <Legend
    onClick={handleLegendClick}
    payload={uniqueYears.map((year) => ({
      id: year,
      value: year,
      type: "circle",
      color: selectedYear === year ? colors?.[year] || "#000" : "#D3D3D3", // Use bar color for selected year, gray for others
    }))}
  />
)}


{isMonthChart && barKeys && visibleBars &&
    barKeys
        .filter((month) => visibleBars[month])
        .map((month, i) => (
            <Bar
                key={i}
                dataKey={month}
                fill={colors[month] || "#000"} // Default to black if color is unavailable
            />
        ))
}
          {isYearChart && data &&  
          <Bar dataKey={'Total'}>
              {data.map(ele => {
                  return <Cell key={ele.Total} fill={ele.Colour} />
              })}
          </Bar>}
          {isWeekChart && barKeys && 
    barKeys
        .filter((week) => visibleBars[week])
        .map((week, i) => (
            <Bar
                key={i}
                dataKey={week}
                fill={colors[week] || "#000"}
            />
        ))
}
            {isTableSalesChart && filteredData &&
              <Bar dataKey="Total" fill="#ccc">
  {filteredData.map((entry, index) => {
    return (
      <Cell key={`cell-${index}`} fill={entry.Colour} />
    )
  })}
</Bar>
            
 }
 {isFlorChart && 
  <Bar dataKey="Total" fill="#ccc">
  {filteredData.map((entry, index) => {
    return (
      <Cell key={`cell-${index}`} fill={entry.Colour} />
    )
  })}
</Bar>
 }
        </BarChart>
        </ResponsiveContainer >
    </Wrapper>
  );
};
export default SimpleBarChart;