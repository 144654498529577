import Wrapper from "../common/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import 'react-datepicker/dist/react-datepicker.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";


const BiReportFilters: React.FC = () => {
    const { t } = useTranslation();

  return (
    <Wrapper onClick={() => {}} classes="pt-10 md:pt-20 flex flex-col items-center justify-center md:pb-4 fixed left-0 md:left-[20%] right-0 bg-[#fafcfff2] z-10">
        <div className="w-[95%] mx-auto flex flex-col md:gap-4 ">

        <div className="w-full">
        <Heading
          align="left"
          children={t("biReport.title")}
          classes="pt-4 md:pt-2 min-w-full text-[22px] md:text-4xl"
          color=""
          size="h1"
        />
        <Text
          align=""
          children={t("biReport.subTitle")}
          classes="text-[15px] md:text-base font-thin text-custom-textGraySec"
          color=""
          size=""
        />
      </div>
      </div>
    </Wrapper>
  );
};

export default BiReportFilters;
