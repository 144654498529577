import React, { useEffect, useState } from 'react';

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 550) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top z-[1000000000000]">
      {isVisible && (
        <button style={{backgroundColor: "#EAECF0"}}
          onClick={scrollToTop}
          className="fixed bottom-4 right-2 p-3 text-white rounded-md"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15V1" stroke="#9E77ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15 6.88942L8 1L1 6.88942" stroke="#9E77ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

        </button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
