import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import "react-datepicker/dist/react-datepicker.css";
import { DateInterface, Location, ZreportFilterProps } from "./ZReportFilters.type";
import useDateFormatter from "../../hooks/useDateFormatter";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
// import useIsMobileDevice from "../../hooks/useIsMobileDevice";

const ZreportFilter: React.FC<ZreportFilterProps> = ({
  sendDateToParent
}) => {
  const { t } = useTranslation();
  const UserMenus = localStorage.getItem("UserMenus");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<Date[]>([]);
  const currentMonth = new Date();
  const token = localStorage.getItem("token");
  const { formatDateSP } = useDateFormatter();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [fromDate, setFromDate] = useState<string>(formatDateSP(yesterday));
  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;
  const menuId = option?.value;
  
      
      const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
      
      const options = UserMenusParsed.map((location: Location) => {
        return { value: location.IdMenu, label: location.MenuName };
      });
     
  useEffect(() => {
    if (location.pathname === "/reports/zreport" && menuId) {
      const getData = async () => {
        const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const requestBody = {
          IdMenu: menuId,
          "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
          "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
        };

        try {
          const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: requestBody,
          });
          const targetDates = apiResponse.data.map((date: DateInterface) => new Date(date.TargetDate));
          setAllowedDatesFromPicker(targetDates.sort((a: Date, b: Date) => b.getTime() - a.getTime()));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getData();
    }
  }, [menuId, location.pathname]);

  const handleSelectChange = (selectedOption: string | number) => {
    const selectedLocationData = UserMenusParsed.find(
      (location: Location) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
      localStorage.setItem("logoUrl", selectedLocationData.LogoImagePath)

    }
    window.location.reload();
  };

  useEffect(() => {
    if (fromDate) {
      sendDateToParent(fromDate);
    }
  }, [fromDate]);


  const { disabledDate } = useDateDisabling({ allowedDatesFromPicker });

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    menuId, 
    token, 
    locationPathname: location.pathname 
  });

  
// const isMobile = useIsMobileDevice();

  return (
    <Wrapper onClick={() => {}} classes="pt-10 md:pt-[68px] flex flex-col items-center justify-center pb-4 fixed left-0 md:left-[20%] right-0 bg-[#fafcfff2] z-10">
      <div className="w-[95%] mx-auto flex flex-col md:flex-row items-end">

      
    <div className="w-full">
            <Heading
              align="left"
              children={t("zreport.title")}
              classes="pt-2 md:pt-0 min-w-full text-[22px] md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("zreport.subTitle")}
              classes="text-[15px] md:text-base font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>
    
      <div className="w-full md:hidden">
        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-3 py-[7px]"
          options={options}
          onChange={handleSelectChange}
          defaultValue={option && option}
        />
        </div>

          <div className="flex flex-col h-fit w-full md:w-8/12 mt-4">
            <p className="text-sm md:text-base mb-1">{t("zreport.date")}</p>
            <fieldset>
              {fromDate &&
              <CustomDatePicker
              dateValue={fromDate}
              onDateChange={setFromDate}
              disabledDate={disabledDate}
              handleMonthChange={(date: Date) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
              placeholder={t("datePicker.placeholder")}
              allowedDates={allowedDatesFromPicker}
              mode={"date"}
              />
            }
            
            
             
            </fieldset>
          </div>
          </div>
    </Wrapper>
  );
};

export default ZreportFilter;
