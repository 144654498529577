import useIsMobileDevice from "../../hooks/useIsMobileDevice";

interface HappyIconProps {
    active: boolean;
    isPDF?: boolean;
}
const HappyIcon: React.FC<HappyIconProps> = ({active = false, isPDF=false}) => {
    const isMobile = useIsMobileDevice();
    const iconSize = isMobile ? 12 : isPDF ? 24 : 16;
    
    const svgIcon = 
    active ? (`
    <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="8" fill="#63D49C"/>
    <ellipse cx="5.88985" cy="5.64193" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
    <ellipse cx="10.4025" cy="5.64193" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
    <path d="M11.1752 8.88965H4.80066C4.44962 8.88965 4.16417 9.17688 4.24727 9.51795C4.53136 10.684 5.59473 12.2878 8.09853 12.2878C10.617 12.2878 11.5224 10.7139 11.7489 9.52109C11.8143 9.1762 11.5262 8.88965 11.1752 8.88965Z" fill="#2A4D06"/>
  </svg>`
 ) : (
 ` <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
 <g opacity="0.4">
   <rect width="16" height="16" rx="8" fill="#63D49C"/>
   <ellipse cx="5.88985" cy="5.64193" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
   <ellipse cx="10.4025" cy="5.64193" rx="0.820513" ry="1.33333" fill="#2A4D06"/>
   <path d="M11.1752 8.88965H4.80066C4.44962 8.88965 4.16417 9.17688 4.24727 9.51795C4.53136 10.684 5.59473 12.2878 8.09853 12.2878C10.617 12.2878 11.5224 10.7139 11.7489 9.52109C11.8143 9.1762 11.5262 8.88965 11.1752 8.88965Z" fill="#2A4D06"/>
 </g>
</svg>`
)
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default HappyIcon;
  

