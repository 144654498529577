const CheckIcon = () => {
    const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1 9.36412L3.85858 13.2283C3.95504 13.3601 4.07864 13.4672 4.2201 13.5416C4.36155 13.6159 4.51719 13.6557 4.67532 13.6576C4.83091 13.6596 4.98495 13.6249 5.12623 13.5565C5.26751 13.4879 5.39248 13.387 5.49206 13.2613L14.6123 1.65771" stroke="#7DD181" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default CheckIcon;
