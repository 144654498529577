import { useState } from "react";
import Wrapper from "../wrapper/Wrapper";
import {   LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer, } from 'recharts';

interface Data {
    name?: string; // Explicitly define 'name' as optional
    Year?: string; // Optional 'Year'
    Month?: string; // Optional 'Month'
    Total?: number; // Optional 'Total'
    color?: string; // Optional 'Colour'
    [key: string]: string | number | undefined; // Allow dynamic keys with 'string | number | undefined'
}

  
interface LineChartProps {
  data: Array<Record<string, any>>;
}

const LineChartComponent: React.FC<LineChartProps> = ({ data }) => {
  const [allDayWeek, setAllDayWeek] = useState([
    { day: "Sun", color: "#9CE3FF", visible: true },
    { day: "Mon", color: "#BDE9AC", visible: true },
    { day: "Tue", color: "#FFEA8F", visible: true },
    { day: "Wed", color: "#F9C98B", visible: true },
    { day: "Thu", color: "#F3A598", visible: true },
    { day: "Fri", color: "#C6A9E3", visible: true },
    { day: "Sat", color: "#EE9FD6", visible: true },
  ]);

    const handleLegendClick = (day: string) => {
      setAllDayWeek((prevState: any) =>
        prevState.map((item: any) =>
          item.day === day ? { ...item, visible: !item.visible } : item
        )
      );
    };
  return (
    <Wrapper onClick={() => {}} classes=" w-full">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
        <CartesianGrid vertical={false} strokeDasharray="0" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          {allDayWeek.map(
            (ele) =>
              ele.visible && (
                <Line
                  key={ele.day}
                  type="monotone"
                  dataKey={ele.day}
                  stroke={ele.color}
                  activeDot={{ r: 8 }}
                />
              )
          )}
           <Legend
            payload={allDayWeek.map((item) => ({
              value: item.day,
              type: "line",
              id: item.day,
              color: item.visible === true ? item.color : "#A9A9A9",
            }))}
            onClick={(e: any) => handleLegendClick(e.id)}
          />
       
        </LineChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default LineChartComponent;

