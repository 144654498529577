import useIsMobileDevice from "../../hooks/useIsMobileDevice";

interface NeutralIconProps {
    active: boolean;
    isPDF?: boolean;
}
const NeutralIcon: React.FC<NeutralIconProps> = ({active = false, isPDF=false}) => {
    const isMobile = useIsMobileDevice();
    const iconSize = isMobile ? 12 : isPDF ? 24 : 16;
    
    const svgIcon = 
    active ? (`
    <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="8" fill="#EFB42C"/>
    <ellipse cx="5.8459" cy="5.64193" rx="0.820513" ry="1.33333" fill="#4D4006"/>
    <ellipse cx="10.3586" cy="5.64193" rx="0.820513" ry="1.33333" fill="#4D4006"/>
    <path d="M11.4321 9.81982H4.71602C4.48511 9.81982 4.29795 10.0071 4.29806 10.238C4.29818 10.4756 4.49087 10.6682 4.72851 10.6682H11.4321C11.6664 10.6682 11.8563 10.4783 11.8563 10.244C11.8563 10.0097 11.6664 9.81982 11.4321 9.81982Z" fill="#4D4006"/>
  </svg>`
 ) : (
 `<svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
 <g opacity="0.4">
   <rect width="16" height="16" rx="8" fill="#EFB42C"/>
   <ellipse cx="5.8459" cy="5.64193" rx="0.820513" ry="1.33333" fill="#4D4006"/>
   <ellipse cx="10.3586" cy="5.64193" rx="0.820513" ry="1.33333" fill="#4D4006"/>
   <path d="M11.4321 9.81982H4.71602C4.48511 9.81982 4.29795 10.0071 4.29806 10.238C4.29818 10.4756 4.49087 10.6682 4.72851 10.6682H11.4321C11.6664 10.6682 11.8563 10.4783 11.8563 10.244C11.8563 10.0097 11.6664 9.81982 11.4321 9.81982Z" fill="#4D4006"/>
 </g>
</svg>`
)
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default NeutralIcon;
  

