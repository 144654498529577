import { useContext, useEffect, useRef, useState } from "react";
import Wrapper from "../common/wrapper/Wrapper";
import FeedbackReportFilter from "../feedbackReportFilters/FeedbackReportFilters";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import i18n from "../../i18n";
import { API_ROUTES } from "../../config";
import FeedbackCard from "../feedbackCard/FeedbackCard";
import { DetailsAnswer } from "./FeedbackReport.type";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import FilterIcon from "../icons/FilterIcon";
import FilterPopover from "../common/table/FilterPopover";
import ResetIcon from "../icons/ResetIcon";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";



const FeedbackReports: React.FC = () => {
    const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
    const { fetchData } = useAxios();
    const token = localStorage.getItem("token");
    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const menuId = localStorage.getItem("MI")
    const [exportType, setExportType] = useState<any>("0");
    const [detailsAnswers, setDetailsAnswers] = useState<DetailsAnswer[]>([]);
    const [openSection, setOpenSection] = useState<string | null>();
    const {t} = useTranslation();
    const location = useLocation();
    const isMobile = useIsMobileDevice();
    const language = i18n.language;
    const [fileUrl, setFileUrl] = useState<null | string>(null);
    const [isSmallLaptop, setIsSmallLaptop] = useState(window.innerWidth <= 1024);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [cardsPerPage, setCardsPerPage] = useState<any>(10);

    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const handleCheckboxChange = (id: number, isChecked: boolean) => {
      setSelectedIds((prev) =>
        isChecked ? [...prev, id] : prev.filter((selectedId) => selectedId !== id)
      );
    };

    const fetchReportData = async () => { 
        if (menuId !== "" && dateFrom !== "" && dateTo !== "") {
          setIsLoadingLiveData(true);
          const feedbackUrl = `${API_ROUTES.getFeedbackReport}idMenu=${menuId}&DateFrom=${dateFrom}&DateTo=${dateTo}&ExportType=${exportType}&Language=${i18n.language}`;
          const config = {
            method: "get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };          try {
            const apiResponse = await fetchData(feedbackUrl, config);
            if(apiResponse.data){
              setCardsPerPage(10)
              setCurrentPage(1)
                if(apiResponse.data.DetailsAnswers){
                    setDetailsAnswers(apiResponse.data.DetailsAnswers)
                }
                if(apiResponse.data.DocumentUrl){
                  setFileUrl(apiResponse.data.DocumentUrl.FileName)
                }
            }else{
            }
            
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoadingLiveData(false);
          }
        }
      };

      const handleFromDate = (date: any) => {
        setDateFrom(date)
      }
      const handleToDate = (date: any) => {
        setDateTo(date)
      }

      const handleExportChange = (exportType: string) => {
        setExportType(exportType[0])
      }
      const [openSections, setOpenSections] = useState<{[key: string]: boolean }>({
        reviews: true,
      });
      const toggleSection = (section: string) => {
        setOpenSections(prevOpenSections => ({
          ...prevOpenSections,
          [section]: !prevOpenSections[section]
        }));
      };

      const totalCards = detailsAnswers.length;
  const totalPages = Math.ceil(totalCards / cardsPerPage);
  const paginatedCards = detailsAnswers.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = isMobile ? 2 : 5;
    const halfMaxButtons = Math.floor(maxPageButtons / 2);
  
    let startPage = Math.max(currentPage - halfMaxButtons, 1);
    let endPage = Math.min(currentPage + halfMaxButtons, totalPages);
  
    if (startPage === 1) {
      endPage = Math.min(maxPageButtons, totalPages);
    }
  
    if (endPage === totalPages) {
      startPage = Math.max(1, totalPages - maxPageButtons + 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${
            currentPage === i
              ? 'border-custom-bgPurple text-custom-bgPurple'
              : ''
          }`}
        >
          {i}
        </button>
      );
    }
  
    if (startPage > 1) {
      pageNumbers.unshift(<span key="start-ellipsis">...</span>);
      pageNumbers.unshift(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${
            currentPage === 1
              ? 'border-custom-bgPurple text-custom-bgPurple'
              : ''
          }`}
        >
          1
        </button>
      );
    }
  
    if (endPage < totalPages) {
      pageNumbers.push(<span key="end-ellipsis">...</span>);
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${
            currentPage === totalPages
              ? 'border-custom-bgPurple text-custom-bgPurple'
              : ''
          }`}
        >
          {totalPages}
        </button>
      );
    }
  
    return pageNumbers;
  };
  
  const feedbackFilterOptions = [
    { value: 0, label: t("receiptReport.receipts")},
    { value: '5', label: t("receiptReport.noPayment") },
    { value: '1', label: t("receiptReport.correction")},
    { value: '2', label: t("receiptReport.transfer")},
    { value: '3', label: t("receiptReport.ownUse")},
    { value: '4', label: t("receiptReport.fromHouse")},
    { value: '6', label: t("receiptReport.correctionPosibleFraud")}
  ]
  

    const [isScrolled, setIsScrolled] = useState(false);
    const [scrollableDiv, setScrollableDiv] = useState(document.querySelector('.scrollable-div'))
    const logoUrl: any = localStorage.getItem("logoUrl")

    const scrollToSlow = (element: any, targetPosition: any, duration: any) => {
      const startPosition = element.scrollTop;
      const distance = targetPosition - startPosition;
      let startTime: any = null;
    
      const easeInOutQuad = (time: any, from: any, distance: any, duration: any) => {
        time /= duration / 2;
        if (time < 1) return (distance / 2) * time * time + from;
        time--;
        return (-distance / 2) * (time * (time - 2) - 1) + from;
      };
    
      const animation = (currentTime: any) => {
        if (startTime === null) startTime = currentTime;
        const elapsedTime = currentTime - startTime;
        const scrollPosition = easeInOutQuad(
          elapsedTime,
          startPosition,
          distance,
          duration
        );
    
        element.scrollTop = scrollPosition;
    
        if (elapsedTime < duration) {
          requestAnimationFrame(animation);
        } else {
          element.scrollTop = targetPosition; // Ensure it ends at the exact position
        }
      };
    
      requestAnimationFrame(animation);
    };
    useEffect(() => {
      scrollToSlow(document.querySelector(".scrollable-div"), 150, 1500); // Scrolls to 150px over 1500ms (1.5 seconds)
    }, [currentPage])
    
    useEffect(() => {
      const test = document.querySelector(".scrollable-div");
      document.querySelector(".scrollable-div")?.addEventListener("scroll", () =>{setIsScrolled(test && test?.scrollTop > 0 ? true : false);
    })
      document.addEventListener('scroll', () =>{ console.log(scrollableDiv)});

    }, [scrollableDiv]);
const selectedCards = detailsAnswers.filter((answer: any) =>
  selectedIds.includes(answer.AnswerGroup)
);
// console.log(window.innerWidth)

const exportToPDF = async () => {
  setIsLoadingLiveData(true);
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4", // Standard A4 size
  });

  const title = t("feedbackReport.title");
  const titleFontSize = 16;
  const titleMargin = 10;

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 10;

  const dateRangeText = `${dateFrom} - ${dateTo}`;
  let y = margin;

  // Add logo if available
  if (logoUrl) {
    const imageWidth = 50;
    const imageHeight = 25;
    const imageX = (pageWidth - imageWidth) / 2;
    pdf.addImage(logoUrl, "PNG", imageX, y, imageWidth, imageHeight);
    y += imageHeight + margin; // Adjust Y to avoid overlapping the text
  }

  // Add title
  pdf.setFontSize(titleFontSize);
  pdf.text(title, pageWidth / 2, y, { align: "center" });
  y += titleFontSize + titleMargin;

  // Add date range
  pdf.setFontSize(12);
  pdf.text(dateRangeText, pageWidth / 2, y, { align: "center" });
  y += titleFontSize;

  // Reference the container element you want to render as PDF
  const feedbackCards = document.querySelectorAll('#html-element-id');
  const htmlElement = document.getElementById("html-element-id");

  if (!htmlElement) {
    setIsLoadingLiveData(false);
    console.error("HTML element not found");
    return;
  }  
  const cardMargin = 10; // Margin between cards
  const cardWidth = pageWidth - 2 * margin; // Full width of the page minus margins
  const cardHeight = 80; // Set a fixed height for the cards
  const cardsOnFirstPage = isMobile ? 1 : isSmallLaptop ? 2 : 3;
  const cardsOnSubsequentPages = isMobile ? 1 : isSmallLaptop ? 3 : 5;

  let cardCounter = 0;
  let currentPage = 1;

  try {
    // Loop through each card and generate a PDF
    for (let i = 0; i < feedbackCards.length; i++) {
      const feedbackCard = feedbackCards[i];

      const htmlElement = feedbackCard as HTMLElement;

      const canvas = await html2canvas(htmlElement, {
        scale: 1.5, // Increase scale for better quality
        useCORS: true, // Allows cross-origin images
      });

      const imageData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * cardWidth) / canvas.width;

      pdf.addImage(imageData, "PNG", margin, y, cardWidth, imgHeight);
      y += imgHeight + cardMargin; // Adjust Y position for next card
      cardCounter++;

      // Determine the number of cards per page
      const cardsPerPage = currentPage === 1 ? cardsOnFirstPage : cardsOnSubsequentPages;

      // Check if the current page is full
      if (cardCounter === cardsPerPage) {
        if (i < feedbackCards.length - 1) {
          pdf.addPage();
        }
        y = margin; // Reset Y for new page
        cardCounter = 0; // Reset card counter
        currentPage++; // Increment page counter
      }
    }

    // Save the PDF
    pdf.save(`Feedback-Report_${dateFrom}_${dateTo}_${i18n.language}_.pdf`);
  } 

    // Save the PDF
   catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    setIsLoadingLiveData(false);
  }
};

// useEffect(() => {
//   if(selectedIds.length = 0){
//     setExportType(0)
//     console.log(selectedIds)
//   }
// }, [selectedIds])

      return (
    <Wrapper onClick={() => {}} classes="w-[100%] mx-auto pt-2 md:pt-4 pb-16 min-h-screen ">
  
    <FeedbackReportFilter isScroll={isScrolled} exportType={""} fileUrl={fileUrl} getDataBe={fetchReportData} handleExportChange={handleExportChange} sendFromDateToParent={handleFromDate} sendToDateToParent={handleToDate}/>
    <div className="relative pt-[27rem] md:pt-[22rem] scrollable-div" style={{ maxHeight: "95dvh", overflowY: "auto" }}>
      <div className="flex flex-col gap-2 w-[95%] mx-auto">
    {detailsAnswers.length > 0 ? (<><div onClick={() => toggleSection("reviews")} className={`${openSections["reviews"] ? "mb-0" : "mb-4"} flex justify-between items-center px-2 md:px-4 rounded-lg border border-gray-200 w-full py-2 md:py-4 bg-white shadow-custom3 `}>{t("feedbackReport.reviews")} {openSections["reviews"] ? (<ArrowUpIcon/>) : (<ArrowDownIcon />)} </div></>) : <></>}
           
    {/* {openSections["reviews"] && detailsAnswers.length > 0 && 
      <div className="w-full flex justify-end">
        <div className={`flex  ${language == "nl" && isMobile ? "w-[80%]" : language == "nl" ? "w-[60%]" : "w-[70%] md:w-[50%]"} right-0 gap-2`}>
      <span className="mt-4">
              <FilterIcon w={isMobile ? "16px" : ""} h={isMobile ? "16px" : ""}/>
      </span>
              <FilterPopover options={feedbackFilterOptions} onFilterChange={() => {}} selected={""} isFeedbackFilter={true} extraLabel="Sort by"/>
              <FilterPopover options={feedbackFilterOptions} onFilterChange={() => {}} selected={""} isFeedbackFilter={true} extraLabel="Filter by"/>
        </div>
      </div>
} */}
<div id="html--id" className="absolute w-[95%] left-[-4000px]" >
{selectedCards.map((answ: DetailsAnswer) => (
              <FeedbackCard
                key={answ.AnswerGroup}
                email={answ.Email}
                foodAndDrinksStars={answ.LikeFood}
                generalStars={answ.AverageDetailsAnswer}
                guests={answ.GuestNumber}
                id={"html-element-id"}
                positiveFeedback={answ.PositiveFeedback}
                serviceStars={answ.WaitingStaff}
                tableName={answ.TableNumber}
                thingsToImprove={answ.ThingsToImprove}
                title=""
                valueForMoney={answ.ValueForMoney}
                onCheckboxChange={handleCheckboxChange}
                checked={selectedIds}
                isForPDF={true}
              />
            ))}
</div>
           <div  className={`${openSections["reviews"] ? "flex flex-col gap-2" : "hidden" }`}>

           {paginatedCards.map((answ: DetailsAnswer, index: number) => (
              <FeedbackCard
                key={answ.AnswerGroup}
                email={answ.Email}
                foodAndDrinksStars={answ.LikeFood}
                generalStars={answ.AverageDetailsAnswer}
                guests={answ.GuestNumber}
                id={answ.AnswerGroup}
                positiveFeedback={answ.PositiveFeedback}
                serviceStars={answ.WaitingStaff}
                tableName={answ.TableNumber}
                thingsToImprove={answ.ThingsToImprove}
                title=""
                valueForMoney={answ.ValueForMoney}
                onCheckboxChange={handleCheckboxChange}
                checked={selectedIds}
              />
            ))}
            
            {detailsAnswers.length > cardsPerPage && (
            <div className="flex justify-between items-center mt-2 text-[8px] md:text-xs w-[97%] mx-auto">
              <div>
              <span className="flex items-center gap-2">
    {t("periodReport.showing")}
    <select
      className="border rounded-lg px-2 py-2"
      value={cardsPerPage}
      onChange={e => {
        setCardsPerPage(e.target.value);
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
    <p style={{ color: "#5A5A5A" }}>
      {t("periodReport.itemsPerPage")}
    </p>
  </span>
              </div>
              <div className="flex">

              <div className="flex ">
                {renderPageNumbers()}
              </div>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-2 py-2 disabled:opacity-50 text-[#5A5A5A]"
                >
                {t("feedbackReport.next")}
              </button>
        
              <button
      onClick={() => handlePageChange(totalPages)}
      disabled={currentPage === totalPages}
      className="px-2 py-2 disabled:opacity-50 text-[#5A5A5A]"
    >
                {t("feedbackReport.end")}
              </button>
                </div>
            </div>
          )}
            </div>
            {/* {selectedIds.length > 0 &&
            <ExportFilterActions isFeedbackReport={true} exportType={exportType} fetchReportData={() => {exportToPDF()}} fileUrl={""} handleExportChange={handleExportChange} hideCSV/>
            } */}

     </div>
    </div>
    <ScrollToTopButton />

    </Wrapper>
  );
};

export default FeedbackReports;
