import useIsMobileDevice from "../../hooks/useIsMobileDevice";

interface SadIconProps {
    active: boolean;
    isPDF?: boolean;
}
const SadIcon: React.FC<SadIconProps> = ({active = false, isPDF = false}) => {
    const isMobile = useIsMobileDevice();
    const iconSize = isMobile ? 12 : isPDF ? 24 : 16;
    
    const svgIcon = 
    active ? (`
    <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
 <rect width="16" height="16" rx="8" fill="#EA441A"/>
 <path d="M7.02195 12.1377L9.08815 12.1377C10.6289 12.1377 11.8546 10.7141 10.7044 9.68892C10.09 9.14135 9.19765 8.73958 7.94444 8.73958C6.73834 8.73958 5.90218 9.12544 5.33338 9.65244C4.20317 10.6996 5.48119 12.1377 7.02195 12.1377Z" fill="#531E13"/>
 <mask id="path-3-inside-1_1156_9567" fill="white">
   <ellipse cx="5.75704" cy="5.64193" rx="0.820513" ry="1.33333"/>
 </mask>
 <ellipse cx="5.75704" cy="5.64193" rx="0.820513" ry="1.33333" fill="#531E13"/>
 <path d="M5.39871 5.64193C5.39871 5.82787 5.34994 5.93979 5.33326 5.96691C5.32632 5.97818 5.3445 5.9431 5.40869 5.89898C5.4791 5.85059 5.60014 5.79642 5.75704 5.79642V8.1541C6.54566 8.1541 7.07265 7.63895 7.3412 7.20256C7.62148 6.74711 7.75639 6.19236 7.75639 5.64193H5.39871ZM5.75704 5.79642C5.91393 5.79642 6.03497 5.85059 6.10538 5.89898C6.16957 5.9431 6.18775 5.97818 6.18082 5.96691C6.16413 5.93979 6.11537 5.82787 6.11537 5.64193H3.75768C3.75768 6.19236 3.89259 6.74711 4.17288 7.20256C4.44142 7.63895 4.96842 8.1541 5.75704 8.1541V5.79642ZM6.11537 5.64193C6.11537 5.45598 6.16413 5.34406 6.18082 5.31695C6.18775 5.30568 6.16957 5.34075 6.10538 5.38487C6.03497 5.43327 5.91393 5.48744 5.75704 5.48744V3.12975C4.96842 3.12975 4.44142 3.6449 4.17288 4.08129C3.89259 4.53675 3.75768 5.09149 3.75768 5.64193H6.11537ZM5.75704 5.48744C5.60014 5.48744 5.4791 5.43327 5.40869 5.38487C5.3445 5.34075 5.32632 5.30568 5.33326 5.31695C5.34994 5.34406 5.39871 5.45598 5.39871 5.64193H7.75639C7.75639 5.09149 7.62148 4.53675 7.3412 4.08129C7.07265 3.6449 6.54566 3.12975 5.75704 3.12975V5.48744Z" fill="#531E13" mask="url(#path-3-inside-1_1156_9567)"/>
 <mask id="path-5-inside-2_1156_9567" fill="white">
   <ellipse cx="10.2707" cy="5.64193" rx="0.820513" ry="1.33333"/>
 </mask>
 <ellipse cx="10.2707" cy="5.64193" rx="0.820513" ry="1.33333" fill="#531E13"/>
 <path d="M9.91238 5.64193C9.91238 5.82787 9.86361 5.93979 9.84693 5.96691C9.83999 5.97818 9.85817 5.9431 9.92236 5.89898C9.99277 5.85059 10.1138 5.79642 10.2707 5.79642V8.1541C11.0593 8.1541 11.5863 7.63895 11.8549 7.20256C12.1352 6.74711 12.2701 6.19236 12.2701 5.64193H9.91238ZM10.2707 5.79642C10.4276 5.79642 10.5486 5.85059 10.6191 5.89898C10.6832 5.9431 10.7014 5.97818 10.6945 5.96691C10.6778 5.93979 10.629 5.82787 10.629 5.64193H8.27135C8.27135 6.19236 8.40627 6.74711 8.68655 7.20256C8.95509 7.63895 9.48209 8.1541 10.2707 8.1541V5.79642ZM10.629 5.64193C10.629 5.45598 10.6778 5.34406 10.6945 5.31695C10.7014 5.30568 10.6832 5.34075 10.6191 5.38487C10.5486 5.43327 10.4276 5.48744 10.2707 5.48744V3.12975C9.48209 3.12975 8.95509 3.6449 8.68655 4.08129C8.40627 4.53675 8.27135 5.09149 8.27135 5.64193H10.629ZM10.2707 5.48744C10.1138 5.48744 9.99277 5.43327 9.92236 5.38487C9.85817 5.34075 9.83999 5.30568 9.84693 5.31695C9.86361 5.34406 9.91238 5.45598 9.91238 5.64193H12.2701C12.2701 5.09149 12.1352 4.53675 11.8549 4.08129C11.5863 3.6449 11.0593 3.12975 10.2707 3.12975V5.48744Z" fill="#531E13" mask="url(#path-5-inside-2_1156_9567)"/>
</svg>`
 ) : (
 ` <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
 <g opacity="0.4">
   <rect width="16" height="16" rx="8" fill="#EA441A"/>
   <path d="M7.02195 12.1377L9.08815 12.1377C10.6289 12.1377 11.8546 10.7141 10.7044 9.68892C10.09 9.14135 9.19765 8.73958 7.94444 8.73958C6.73834 8.73958 5.90218 9.12544 5.33338 9.65244C4.20317 10.6996 5.48119 12.1377 7.02195 12.1377Z" fill="#531E13"/>
   <mask id="path-3-inside-1_1156_8654" fill="white">
     <ellipse cx="5.75704" cy="5.64193" rx="0.820513" ry="1.33333"/>
   </mask>
   <ellipse cx="5.75704" cy="5.64193" rx="0.820513" ry="1.33333" fill="#531E13"/>
   <path d="M5.39871 5.64193C5.39871 5.82787 5.34994 5.93979 5.33326 5.96691C5.32632 5.97818 5.3445 5.9431 5.40869 5.89898C5.4791 5.85059 5.60014 5.79642 5.75704 5.79642V8.1541C6.54566 8.1541 7.07265 7.63895 7.3412 7.20256C7.62148 6.74711 7.75639 6.19236 7.75639 5.64193H5.39871ZM5.75704 5.79642C5.91393 5.79642 6.03497 5.85059 6.10538 5.89898C6.16957 5.9431 6.18775 5.97818 6.18082 5.96691C6.16413 5.93979 6.11537 5.82787 6.11537 5.64193H3.75768C3.75768 6.19236 3.89259 6.74711 4.17288 7.20256C4.44142 7.63895 4.96842 8.1541 5.75704 8.1541V5.79642ZM6.11537 5.64193C6.11537 5.45598 6.16413 5.34406 6.18082 5.31695C6.18775 5.30568 6.16957 5.34075 6.10538 5.38487C6.03497 5.43327 5.91393 5.48744 5.75704 5.48744V3.12975C4.96842 3.12975 4.44142 3.6449 4.17288 4.08129C3.89259 4.53675 3.75768 5.09149 3.75768 5.64193H6.11537ZM5.75704 5.48744C5.60014 5.48744 5.4791 5.43327 5.40869 5.38487C5.3445 5.34075 5.32632 5.30568 5.33326 5.31695C5.34994 5.34406 5.39871 5.45598 5.39871 5.64193H7.75639C7.75639 5.09149 7.62148 4.53675 7.3412 4.08129C7.07265 3.6449 6.54566 3.12975 5.75704 3.12975V5.48744Z" fill="#531E13" mask="url(#path-3-inside-1_1156_8654)"/>
   <mask id="path-5-inside-2_1156_8654" fill="white">
     <ellipse cx="10.2707" cy="5.64193" rx="0.820513" ry="1.33333"/>
   </mask>
   <ellipse cx="10.2707" cy="5.64193" rx="0.820513" ry="1.33333" fill="#531E13"/>
   <path d="M9.91238 5.64193C9.91238 5.82787 9.86361 5.93979 9.84693 5.96691C9.83999 5.97818 9.85817 5.9431 9.92236 5.89898C9.99277 5.85059 10.1138 5.79642 10.2707 5.79642V8.1541C11.0593 8.1541 11.5863 7.63895 11.8549 7.20256C12.1352 6.74711 12.2701 6.19236 12.2701 5.64193H9.91238ZM10.2707 5.79642C10.4276 5.79642 10.5486 5.85059 10.6191 5.89898C10.6832 5.9431 10.7014 5.97818 10.6945 5.96691C10.6778 5.93979 10.629 5.82787 10.629 5.64193H8.27135C8.27135 6.19236 8.40627 6.74711 8.68655 7.20256C8.95509 7.63895 9.48209 8.1541 10.2707 8.1541V5.79642ZM10.629 5.64193C10.629 5.45598 10.6778 5.34406 10.6945 5.31695C10.7014 5.30568 10.6832 5.34075 10.6191 5.38487C10.5486 5.43327 10.4276 5.48744 10.2707 5.48744V3.12975C9.48209 3.12975 8.95509 3.6449 8.68655 4.08129C8.40627 4.53675 8.27135 5.09149 8.27135 5.64193H10.629ZM10.2707 5.48744C10.1138 5.48744 9.99277 5.43327 9.92236 5.38487C9.85817 5.34075 9.83999 5.30568 9.84693 5.31695C9.86361 5.34406 9.91238 5.45598 9.91238 5.64193H12.2701C12.2701 5.09149 12.1352 4.53675 11.8549 4.08129C11.5863 3.6449 11.0593 3.12975 10.2707 3.12975V5.48744Z" fill="#531E13" mask="url(#path-5-inside-2_1156_8654)"/>
 </g>
</svg>`
)
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default SadIcon;
  

