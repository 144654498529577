import { useTranslation } from "react-i18next";
import CheckIcon from "../icons/CheckIcon";
import FeedbackArrowUpIcon from "../icons/FeedbackArrowUpIcon";
import { FeedbackCardProps } from "./FeedbackCard.type";
// import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import SadIcon from "../icons/SadIcon";
import NeutralSadIcon from "../icons/NeutralSadIcon";
import NeutralIcon from "../icons/NeutralIcon";
import NeutralHappyIcon from "../icons/NeutralHappyIcon";
import HappyIcon from "../icons/HappyIcon";

const FeedbackCard: React.FC<FeedbackCardProps> = ({id, title, email, tableName, guests, positiveFeedback, thingsToImprove, foodAndDrinksStars, serviceStars, generalStars, valueForMoney, onCheckboxChange, checked, isForPDF }) => {
    const { t } = useTranslation();
    // const isMobile = useIsMobileDevice();
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onCheckboxChange(id, event.target.checked);
      };
    const capitalizeFirstLetter = (string: string) => {
        if (!string) return "/";
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const renderFeedbackIcons = (rating: number) => {
        return (
          <div className="flex gap-1">
            <SadIcon active={rating > 0 && rating <= 2} isPDF={isForPDF}/>
            <NeutralSadIcon active={rating > 2 && rating <= 4} isPDF={isForPDF}/>
            <NeutralIcon active={rating > 4 && rating <= 6} isPDF={isForPDF}/>
            <NeutralHappyIcon active={rating > 6 && rating <= 8} isPDF={isForPDF}/>
            <HappyIcon active={rating > 8} isPDF={isForPDF}/>
          </div>
        );
      };
      
    return(
        <div className={`min-w-full ${isForPDF ? "bg-[#F3F4F6] mb-4" : "bg-white"}  flex flex-col items-start p-4 pb-8 rounded-2xl shadow-custom3 justify-between `} key={id} id={id}>
            <div className="flex justify-between w-full">

            <div className="flex items-start">
                <input 
                type="checkbox" 
                className={`mr-2 md:mr-4 mt-1.5 ${isForPDF ? "hidden" : ""}`}
                onChange={handleCheckboxChange}
                checked={checked.includes(id)}
                />
                <span>
                    <p className={`font-semibold ${isForPDF ? "ml-6 text-lg" : "text-base"}`}>{t("feedbackReport.customerFeedback")}</p>
                    <p className={` text-[#5A5A5A] ${isForPDF ? "ml-6 text-base" : "text-xs"}`}>{email ? email : "/"}</p>
                </span>
            </div>
            <div className="md:min-w-[76px] min-w-[40px] max-h-[28px] md:max-h-[48px] text-xs md:text-2xl bg-custom-gradient from-purpleLight to-purpleLighter p-2 flex items-center justify-center rounded-xl md:rounded-2xl gap-2" style={{ backgroundImage: "linear-gradient(109deg, #DABAF8 8.2%, #DDBFF8 29.03%, #EAD9FB 88.02%)" }}>
                {generalStars}
            </div>
            </div>
            <div className="flex w-full flex-col md:flex-row">
                <div className="w-1/2 ml-5 md:ml-7 mt-2">
                    <p className={` text-[#5A5A5A] ${isForPDF ? "text-base " : "text-xs"}`}>{t("feedbackReport.table")}: #{tableName} | {t("feedbackReport.guests")}: {guests}</p>
                        <span>
                            <span className={`flex items-center text-custom-green mt-4 ${isForPDF ? "text-lg" : "text-base"}`}><CheckIcon /> <p className="ml-2">{t("feedbackReport.positiveFeedback")}</p></span>
                            <p className="text-custom-textGraySec">
                                {positiveFeedback ? capitalizeFirstLetter(positiveFeedback) : "/"}
                                </p>
                        </span>
                        <span>
                            <span className={`flex items-center text-custom-orange mt-4 ${isForPDF ? "text-lg" : "text-base"}`}><FeedbackArrowUpIcon /> <p className="ml-2">{t("feedbackReport.thingsToImprove")}</p></span>
                            <p className="text-custom-textGraySec">
                                {thingsToImprove ? capitalizeFirstLetter(thingsToImprove) : "/"}
                                </p>
                        </span>
                </div>
                <div className="w-1/2 ml-5 md:ml-0 mt-6 md:mt-1">
                    <span>
                        <span className="flex items-center">
                            {/* <FoodQualityIcon />  */}
                            <p className={`${isForPDF ? "text-base" : "text-sm"}`}>{t("feedbackReport.serviceQuality")}</p></span>
                        <span className="flex gap-1 mt-1 text-[#5A5A5A]">
        
                     {
                         renderFeedbackIcons(serviceStars ? serviceStars : 0)
                     }
                        </span>

                    </span>

                    <span>
                        <span className="flex items-center mt-2">
                            {/* <ServiceQualityIcon />  */}
                            <p className={`${isForPDF ? "text-base" : "text-sm"}`}>{t("feedbackReport.valueForMoney")}</p></span>
                        <span className="flex gap-1 mt-1 text-[#5A5A5A]">
                            {
                            renderFeedbackIcons(valueForMoney ? valueForMoney : 0)
                            }
                        </span>

                    </span>

                    <span>
                        <span className="flex items-center mt-2">
                            {/* <ValueForMoneyIcon /> */}
                            <p className={`${isForPDF ? "text-base" : "text-sm"}`}>{t("feedbackReport.foodQuality")}</p></span>
                        <span className="flex gap-1  mt-1 text-[#5A5A5A]">
                        {
                            renderFeedbackIcons(foodAndDrinksStars ? foodAndDrinksStars : 0)
                            }
                        </span>

                    </span>
                </div>

            </div>

        </div>
    )
}

export default FeedbackCard;