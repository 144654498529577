import { useContext, useState } from "react";
import jsPDF from "jspdf";
import { toPng } from "html-to-image"; // Ensure you have this package installed
import { useTranslation } from "react-i18next";
import { LoadingLiveDataContext } from "../context/isLoadingLiveDataContext";

interface UseExportBiDataToPDFProps {
  chartRefs: React.RefObject<HTMLDivElement[]>;
  chartsPerRow: number;
  dateFrom: string;
  dateTo: string;
  logoUrl: string;
  chartTitles: { [key: number]: string };
}

export const useExportBiDataToPDF = ({
  chartRefs,
  chartsPerRow,
  dateFrom,
  dateTo,
  logoUrl,
  chartTitles,
}: UseExportBiDataToPDFProps) => {
  const { t, i18n } = useTranslation();
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);

  

  const exportToPDF = async () => {
    setIsLoadingLiveData(true);
    const pdf = new jsPDF();
    pdf.setTextColor("#343A40");

    const title = t("biReport.title");
    const titleFontSize = 16;
    const titleMargin = 10;

    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 10;
    const chartWidth = (pageWidth - margin * 2) / chartsPerRow;
    const chartHeight = 50;

    const dateRangeText = `${dateFrom} - ${dateTo}`;
    let x = margin;
    let y = margin + titleFontSize + titleMargin;

    if (logoUrl) {
      const imageWidth = 50;
      const imageHeight = 25;
      const imageX = (pageWidth - imageWidth) / 2;
      const imageY = margin;
      pdf.addImage(logoUrl, "PNG", imageX, imageY, imageWidth, imageHeight);
    }

    y += 10;

    pdf.setFontSize(16);
    pdf.text(title, pageWidth / 2, y, { align: "center" });
    y +=  titleMargin;

    pdf.setFontSize(16);
    pdf.text(dateRangeText, pageWidth / 2, y, { align: "center" });
    y += titleFontSize + titleMargin * 2;

    if (chartRefs.current) {
    for (const [index, chart] of chartRefs.current.entries()) {
     try {
      const chartNumber = index; // Use the index as the chart number (e.g., 1, 2, 3...)
      const chartTitle = chartTitles[chartNumber] || `Chart ${chartNumber}`; // Get the title dynamically
      const chartTopMargin = 1; // Add margin on top of the chart

      const chartImage = await toPng(chart); // Convert chart to image

      // Add the chart title text above the chart
      const chartTitleFontSize = 16; // Font size for the chart title
      pdf.setFontSize(chartTitleFontSize);
      pdf.text(chartTitle, pageWidth / 2, y, { align: "center" });
      y += chartTitleFontSize + chartTopMargin;

      // Add the chart image to the PDF
      pdf.addImage(chartImage, "PNG", x, y, chartWidth, chartHeight);

      // Update position for the next chart
      x += chartWidth;
      y += chartTitleFontSize + 5; // Add some spacing after the title

      // Move to the next row if the current row is filled
      if ((index + 1) % chartsPerRow === 0) {
        x = margin;
        y += chartHeight;
      }

      // If the charts exceed the page height, start a new page
      if (y + chartHeight > pageHeight - margin) {
        pdf.addPage();
        x = margin;
        y = margin + titleFontSize + titleMargin; // Reset Y considering the title height
      }
    } catch (error) {
      console.error("Error exporting chart:", error);
    }
    }
    }

    setIsLoadingLiveData(false);
    pdf.save(`Bi-Data-Report_${dateFrom}_${dateTo}_${i18n.language}_.pdf`);
  };

  return { exportToPDF };
};
