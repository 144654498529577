import useIsMobileDevice from "../../hooks/useIsMobileDevice";

interface NeutralSadIconProps {
    active: boolean;
    isPDF?: boolean;
}
const NeutralSadIcon: React.FC<NeutralSadIconProps> = ({active = false, isPDF=false}) => {
    const isMobile = useIsMobileDevice();
    const iconSize = isMobile ? 12 : isPDF ? 24 : 16;
    
    const svgIcon = 
    active ? (`
    <svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="8" fill="#E77527"/>
    <path d="M6.53821 5.64193C6.53821 5.9903 6.45106 6.29955 6.31691 6.51754C6.18148 6.73761 6.01136 6.84706 5.8459 6.84706C5.68045 6.84706 5.51033 6.73761 5.3749 6.51754C5.24075 6.29955 5.1536 5.9903 5.1536 5.64193C5.1536 5.29356 5.24075 4.9843 5.3749 4.76631C5.51033 4.54624 5.68045 4.4368 5.8459 4.4368C6.01136 4.4368 6.18148 4.54624 6.31691 4.76631C6.45106 4.9843 6.53821 5.29356 6.53821 5.64193Z" fill="#4D2B06" stroke="#4D2B06" stroke-width="0.25641"/>
    <path d="M11.0499 5.64193C11.0499 5.9903 10.9628 6.29955 10.8286 6.51754C10.6932 6.73761 10.5231 6.84706 10.3576 6.84706C10.1922 6.84706 10.022 6.73761 9.88662 6.51754C9.75247 6.29955 9.66531 5.9903 9.66531 5.64193C9.66531 5.29356 9.75247 4.9843 9.88662 4.76631C10.022 4.54624 10.1922 4.4368 10.3576 4.4368C10.5231 4.4368 10.6932 4.54624 10.8286 4.76631C10.9628 4.9843 11.0499 5.29356 11.0499 5.64193Z" fill="#4D2B06" stroke="#4D2B06" stroke-width="0.25641"/>
    <path d="M11.0762 11.7959C11.0762 10.759 10.3485 9.11801 7.89669 9.11801C5.44491 9.11801 4.92233 10.8616 4.92233 11.7959" stroke="#4D2B06" stroke-width="1.28205"/>
  </svg>`
 ) : (
 `<svg xmlns="http://www.w3.org/2000/svg" width=${iconSize} height=${iconSize} viewBox="0 0 16 16" fill="none">
 <g opacity="0.4">
   <rect width="16" height="16" rx="8" fill="#E77527"/>
   <path d="M6.53821 5.64193C6.53821 5.9903 6.45106 6.29955 6.31691 6.51754C6.18148 6.73761 6.01136 6.84706 5.8459 6.84706C5.68045 6.84706 5.51033 6.73761 5.3749 6.51754C5.24075 6.29955 5.1536 5.9903 5.1536 5.64193C5.1536 5.29356 5.24075 4.9843 5.3749 4.76631C5.51033 4.54624 5.68045 4.4368 5.8459 4.4368C6.01136 4.4368 6.18148 4.54624 6.31691 4.76631C6.45106 4.9843 6.53821 5.29356 6.53821 5.64193Z" fill="#4D2B06" stroke="#4D2B06" stroke-width="0.25641"/>
   <path d="M11.0499 5.64193C11.0499 5.9903 10.9628 6.29955 10.8286 6.51754C10.6932 6.73761 10.5231 6.84706 10.3576 6.84706C10.1922 6.84706 10.022 6.73761 9.88662 6.51754C9.75247 6.29955 9.66531 5.9903 9.66531 5.64193C9.66531 5.29356 9.75247 4.9843 9.88662 4.76631C10.022 4.54624 10.1922 4.4368 10.3576 4.4368C10.5231 4.4368 10.6932 4.54624 10.8286 4.76631C10.9628 4.9843 11.0499 5.29356 11.0499 5.64193Z" fill="#4D2B06" stroke="#4D2B06" stroke-width="0.25641"/>
   <path d="M11.0762 11.7959C11.0762 10.759 10.3485 9.11801 7.89669 9.11801C5.44491 9.11801 4.92233 10.8616 4.92233 11.7959" stroke="#4D2B06" stroke-width="1.28205"/>
 </g>
</svg>`
)
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default NeutralSadIcon;
  

