import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import VatReportFilters from "../vatReportFilters/VatReportFilters";
import useAxios from "../../hooks/useAxios";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import i18n from "../../i18n";
import { API_ROUTES } from "../../config";
import Table from "../common/table/Table";
import { getTableOptions } from "./VatReportTableOptions";
import { getTableHeaders } from "./VatReportTableConfig";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import ArrowDownIcon from "../icons/ArrowDownIcon";

type VatEntry = {
    VatName: string;
    ExclVAT: number;
    VAT: number;
  };


const VatReport: React.FC = () => {
    const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
    const { fetchData } = useAxios();
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [exportType, setExportType] = useState<string>("0");
  const token = localStorage.getItem("token");
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();
  const menuId = localStorage.getItem("MI");
  const [OOT, setOOT] = useState<any>();
  const [allTaxes, setAllTaxes] = useState<any>();
  const [parentTaxes, setParentTaxes] = useState<any>();
  const taxParentToggle = localStorage.getItem("toggle_1")
  const isTaxParentGroup = taxParentToggle ? JSON.parse(taxParentToggle ? taxParentToggle : "") : true;

  const handleExportChange = (exportType: string) => {
    setExportType(exportType[0])
  }
  const handleFromDate = (date: any) => {
    setDateFrom(date)
  }
  const handleToDate = (date: any) => {
    setDateTo(date)
  }

  const fetchReportData = async () => { 
    if (menuId !== "" && dateFrom !== "" && dateTo !== "") {
      const requestBody = {
        IdMenu: menuId,
        TargetDate: dateFrom,
        TargetDateTo: dateTo,
        Language: i18n.language,
        IsBtwChecked: "true",
        isDiscountChecked: "false",
        ExportType: exportType
    };
      setIsLoadingLiveData(true);

      try {
        const apiResponse = await fetchData(API_ROUTES.getBtwReport, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        });
        setFileUrl(apiResponse.data.DocumentUrlBTW && apiResponse.data.DocumentUrlBTW.FileName);
        setOOT(apiResponse.data.OutOfTurnover.alltaxesOOT);
        setAllTaxes(apiResponse.data.alltaxes);
        setParentTaxes(apiResponse.data.TaxParentGroupDetails)
      } catch (error) {
        setFileUrl("");
        setOOT([]);
        setAllTaxes([]);
        setParentTaxes([])
        console.error(error)
      } finally {
        setIsLoadingLiveData(false);
      }
    }
  };
  const prepareTableData = (data: any, isOOT: boolean) => {
      const taxExclKey = isOOT ? "taxExclVATOOT" : "taxExclVAT";
      const taxVATKey = isOOT ? "taxVATOOT" : "taxVAT";
    if (!data || !data[taxExclKey] || !data[taxVATKey]) {
        return []; // Return an empty array if data is not ready
      }

      const rows = data[taxExclKey].map((item: any, index: any) => ({
          VatName: item.VatName,
          ExclVAT: item.Ammount.toFixed(2),
          VAT: data[taxVATKey][index]?.Ammount.toFixed(2) || 0,
        }));
        
        const total = {
            VatName: "Total",
            ExclVAT: data[taxExclKey].reduce((sum: any, item: any) => sum + item.Ammount, 0).toFixed(2),
            VAT: data[taxVATKey].reduce((sum: any, item: any) => sum + item.Ammount, 0).toFixed(2)
        };
        
        return [...rows, total];
    
    };
  const allTaxesTableData = prepareTableData(allTaxes, false);
  const OOTTableData = prepareTableData(OOT, true)

  const tableTH = getTableHeaders(t);
  const options = getTableOptions(allTaxesTableData, tableTH);
  const OOToptions = getTableOptions(OOTTableData, tableTH)
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    vat: true,
    parentVat: false,
    oot: false
  });
  const toggleSection = (section: string) => {
    setOpenSections(prevOpenSections => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section]
    }));
  };
  let final;
  if(parentTaxes) {

      const result = parentTaxes?.reduce((acc: Record<string, VatEntry>, tax: any) => {
          const { VatName, Ammount, TaxType } = tax;
          
          if (!acc[VatName]) {
              acc[VatName] = { VatName, ExclVAT: 0, VAT: 0 };
            }
            
            if (TaxType === 2) {
                acc[VatName].VAT += Ammount;
            } else if (TaxType === 1) {
                acc[VatName].ExclVAT += Ammount;
            }
            
            acc["Total"].VAT += (TaxType === 2) ? Ammount : 0;
            acc["Total"].ExclVAT += (TaxType === 1) ? Ammount : 0;
            
            return acc;
        }, { "Total": { VatName: "Total", ExclVAT: 0, VAT: 0 } } as Record<string, VatEntry>);
        
        final = Object.entries(result)
        .filter(([key]) => key !== "Total") // Filter out "Total" temporarily
        .map(([, value]) => {
            const entry = value as any; // Cast to VatEntry type
            return {
                ...entry,
                ExclVAT: parseFloat(entry.ExclVAT),
                VAT: parseFloat(entry.VAT),
            };
        });
        final.push({
            ...result["Total"],
            ExclVAT: parseFloat(result["Total"].ExclVAT.toFixed(2)),
            VAT: parseFloat(result["Total"].VAT.toFixed(2)),
        }); 
    }
    const parentTaxOptions = getTableOptions(final, tableTH)

    const [isScrolled, setIsScrolled] = useState(false);
    const [scrollableDiv, setScrollableDiv] = useState(document.querySelector('.scrollable-div'))

    
    useEffect(() => {
      const test = document.querySelector(".scrollable-div");
      document.querySelector(".scrollable-div")?.addEventListener("scroll", () =>{setIsScrolled(test && test?.scrollTop > 0 ? true : false);
    })
      document.addEventListener('scroll', () =>{ console.log(scrollableDiv)});

    }, [scrollableDiv]);

    return (
            <Wrapper onClick={() => {}} classes="w-[95%] mx-auto pt-2 md:pt-4 pb-16 min-h-screen">
        <VatReportFilters isScroll={isScrolled} exportType={exportType} fileUrl={fileUrl} getDataBe={fetchReportData} handleExportChange={handleExportChange} sendFromDateToParent={handleFromDate} sendToDateToParent={handleToDate}/>
        <div className="relative pt-[27rem] md:pt-[22rem] scrollable-div" style={{ maxHeight: "95dvh", overflowY: "auto" }}>
       {allTaxes?.taxExclVAT.length > 0 && allTaxes?.taxVAT.length > 0 && <div 
      onClick={() => toggleSection("vat")} 
      className="flex justify-between items-center mb-2 px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom text-sm md:text-base"
      >
          {t("vatReport.vat")} {openSections["vat"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>}
        {allTaxes?.taxExclVAT.length > 0 && allTaxes?.taxVAT.length > 0 && openSections["vat"] && <Table options={options} isVatReport={true}/>}
        
        {parentTaxOptions.data && parentTaxOptions.data.length > 1 && isTaxParentGroup && <div 
      onClick={() => toggleSection("parentVat")} 
      className="flex justify-between items-center mb-2 px-2 md:px-4 rounded-lg border border-gray-200 w-full relative my-2 py-2 md:py-4 bg-white shadow-custom text-sm md:text-base"
      >
          {t("vatReport.parentVat")} {openSections["parentVat"] ? (<ArrowUpIcon color="#6941C6"/>) : (<ArrowDownIcon color="#6941C6"/>)} 
        </div>}
        {parentTaxOptions.data && parentTaxOptions.data.length > 1 && openSections["parentVat"] && isTaxParentGroup &&
        <Table options={parentTaxOptions} isVatReport={true}/>
        }

        {OOT?.taxExclVATOOT.length > 0 && OOT?.taxVATOOT.length > 0 && <div 
      onClick={() => toggleSection("oot")} 
      className="flex justify-between items-center px-2 md:px-4 rounded-lg border border-custom-bgOrange w-full relative my-2 mb-2 py-2 md:py-4 mt-4 bg-custom-bgOrange text-white shadow-custom3"
      >
          {t("vatReport.oot")} {openSections["oot"] ? (<ArrowUpIcon color="white"/>) : (<ArrowDownIcon color="white"/>)} 
        </div>}
        {OOT?.taxExclVATOOT.length > 0 && OOT?.taxVATOOT.length > 0 && openSections["oot"] && <Table options={OOToptions} isVatReport={true} isOOT={true}/>}
    
        </div>
            
    </Wrapper>
  );
};

export default VatReport;
