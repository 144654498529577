import { useContext, useEffect, useState } from "react";
import Heading from "../common/heading/Heading";
import Input from "../common/input/Input";
import Label from "../common/label/Label";
import Button from "../common/button/Button";
import { useTranslation } from "react-i18next";
import { PrivilegesContext } from "../../context/privilegesContext";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import Text from "../common/text/Text";

const MobileLogInComponent: React.FC = () => {
  const [error, setError] = useState<string>("");
  const { fetchData } = useAxios();
  const navigate = useNavigate();
  const { setShouldRenderReports, setRestaurantMenus } =
    useContext(PrivilegesContext);
  const [, setCookie]: any = useCookies(["token", "isLogIn"]);
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ) => {
    setInputValues({
      ...inputValues,
      [fieldName]: e.target.value,
    });
  };

  // const hashPassword = async (password: any) => {
  //   const encoder = new TextEncoder();
  //   const data = encoder.encode(password);
  //   const buffer = await crypto.subtle.digest("SHA-256", data);
  //   const hashedPassword = Array.from(new Uint8Array(buffer))
  //     .map((byte) => byte.toString(16).padStart(2, "0"))
  //     .join("");
  //   return hashedPassword;
  // };

  // hashPassword("a").then((hashedPassword: string) => {
  //   console.log("Hashed password:", hashedPassword);
  // });

  const handleLogin = () => {
    const path = `${API_ROUTES.getToken}`;
    const formData = new URLSearchParams();
    formData.append("userName", inputValues.fullName);
    formData.append("password", inputValues.password);
    formData.append("grant_type", "password");

    const config = {
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      fetchData(path, config).then((res) => {
        if (res.error) {
          setError(t("login.error"));
          console.log(error);
        } else {
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("isLogIn", JSON.stringify(true));
          localStorage.setItem("refreshToken", res.data.refresh_token)
          localStorage.setItem("expires", res.data.expires_in)
          
          const expiresInMilliseconds = res.data.expires_in * 1000;
          const expirationDate = new Date(Date.now() + expiresInMilliseconds);
          const expirationDateUTC = expirationDate.toUTCString();
          document.cookie = `token=${res.data.access_token}; expires=${expirationDateUTC}; path=/`;
          document.cookie = `refresh_token=${res.data.refresh_token}; expires=${expirationDateUTC}; path=/`;
          document.cookie = `expires_date=${expirationDate}; expires=${expirationDate}; path=/`;

          fetchUserPrivileges(res.data.access_token, inputValues.fullName);
        }
      });
    } catch (err) {
      console.log("error fetching token");
      console.log(err);
    }
  };

  const fetchUserPrivileges = (token: string, username: string) => {
    const privilegesUrl = `${API_ROUTES.getUserPrivileges}${username}`;

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetchData(privilegesUrl, config)
      .then((res) => {
        localStorage.setItem("UserMenus", JSON.stringify(res.data.UserMenus));
        localStorage.setItem("priv", JSON.stringify(res.data.UserPrivilegesList));
        if(res.data.UserMenus.length > 0) {
          console.log(res.data.UserMenus[0].FullAddress)
          localStorage.setItem("menuId", JSON.stringify(res.data.UserMenus[0]))
          localStorage.setItem("MI", JSON.stringify(res.data.UserMenus[0].IdMenu))
          localStorage.setItem("longitude", JSON.stringify(res.data.UserMenus[0].Longitude))
          localStorage.setItem("latitude", JSON.stringify(res.data.UserMenus[0].Latitude))
          localStorage.setItem("fullAddress", JSON.stringify(res.data.UserMenus[0].FullAddress))
          localStorage.setItem("logoUrl", JSON.stringify(res.data.LogoImagePath))

          if(!localStorage.getItem("toggle_1")){
            localStorage.setItem("toggle_1", "false");
          }
        }else {
          localStorage.setItem("menuId", JSON.stringify(null))
          localStorage.setItem("MI", JSON.stringify(null))
          localStorage.setItem("longitude", JSON.stringify(null))
          localStorage.setItem("latitude", JSON.stringify(null))
          localStorage.setItem("fullAddres", JSON.stringify(null))
          localStorage.setItem("logoUrl", JSON.stringify(null))
        }

        setShouldRenderReports(true);
        navigate("/reports/sales");
      })
      .catch((error: any) => {
        console.error("Error fetching user privilages:", error);
      });
  };


  return (
    <div className=" w-screen">
      <div className="bg-white rounded-lg w-full h-screen pt-4 p-4">
        <img src="/guestit_black_1.svg" alt="" className="w-1/2 -ml-7" />
        <Heading
          children={t("login.welcomeBack")}
          classes="flex items-center text-lg"
          size="h3"
          color="black"
          align="text-left"
        />
        <Text
          align=""
          children={t("login.welcomeBackText")}
          classes=""
          color=""
          size=""
        />

        <form className="flex flex-col pt-8">
          <Label
            htmlFor=""
            text={`${t("login.username")}*`}
            classes="uppercase text-sm font-bold leading-5 text-custom-gray mb-1"
          />
          <Input
            onChange={(e) => handleInputChange(e, "fullName")}
            size=""
            value={inputValues.fullName}
            classes="border border-gray-300 px-4 py-2 rounded mb-4 focus:border-gray-400 focus:outline-none"
            type="email"
            autocomplete="username"
          />

          <Label
            htmlFor=""
            text={`${t("login.password")}*`}
            classes="uppercase text-sm font-bold leading-5 text-custom-gray mb-1"
          />
          <Input
            onChange={(e) => handleInputChange(e, "password")}
            size=""
            value={inputValues.password}
            classes="border border-gray-300 px-4 py-2 rounded mb-2 focus:border-gray-400 focus:outline-none"
            type="password"
            autocomplete="current-password"
          />
          {error ? (
            <div className="text-red-500 text-sm mt-1 bg-red-100 py-2 px-2 rounded border border-red-500">
              {error}
            </div>
          ) : (
            ""
          )}
        </form>
        <div className="flex justify-between items-center h-24 ">
          <p className="text-left text-custom-blue text-sm py-4">
            {/* <a href="/forgotpassword">{t("login.forgotPw")}</a> */}
          </p>

          <Button
            label={t("login.login")}
            onClick={handleLogin}
            classes="w-full py-3 rounded"
            type="secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileLogInComponent;
