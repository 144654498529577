import Wrapper from "../common/wrapper/Wrapper";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import BiReportFilters from "../biReportFilter/BiReportFilter";
import SimpleBarChart from "../common/simpleBarChart/SimpleBarChart";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LineChartComponent from "../common/lineChart/LineChart";
import data from "../../constants/biDummyData.json"
import { useExportBiDataToPDF } from "../../hooks/useExportBiDataToPDF";

const BiReport: React.FC = () => {
const chartRefs = useRef<HTMLDivElement[]>([]); // Store references to chart containers
const barKeys = ["Total"]; // Use 'Total' for YearReport
const [selectedPeriod, setSelectedPeriod] = useState<string>("month")
const addChartRef = (index: number, element: HTMLDivElement | null) => {
  if (element) chartRefs.current[index] = element;
};
const [dateFrom, setDateFrom] = useState<any>("01.11.2024");
const [dateTo, setDateTo] = useState<any>("31.12.2024");
const [chartsPerRow, setChartsPerRow] = useState(1); // Default is 3 charts per row
const logoUrl: any = localStorage.getItem("logoUrl")
const { t } = useTranslation();

  const chartTitles: any = {
    0: `${t("biReport.totalTurnover")} ${t(`biReport.${selectedPeriod}`)}`, 
    1: `${t("biReport.totalTurnover")} ${t(`biReport.week`)}`,
    2: `${t("biReport.turnoverPerHourPerDay")}`,
    3: `${t("biReport.topTableSales")}`,
    4: `${t("biReport.turnoverPerFloor")}`,
  };

const { exportToPDF } = useExportBiDataToPDF({
  chartRefs,
  chartsPerRow,
  dateFrom,
  dateTo,
  logoUrl,
  chartTitles,
});


const transformWeekDayAndTimeData = (report: any[]): Record<string, any>[] => {
    const allWeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
    // Group data by time
    const groupedData = report.reduce((acc: any, item) => {
      const timeKey = `${item.Time}:00`; // Format time as "11:00", "12:00", etc.
      const weekDayKey = allWeekDays[parseInt(item.WeekDay, 10) - 1];
  
      if (!acc[timeKey]) {
        acc[timeKey] = { name: timeKey }; // Initialize entry for this time slot
      }
  
      acc[timeKey][weekDayKey] = item.Total; // Assign total to the correct weekday
      return acc;
    }, {});
  
    return Object.values(groupedData) as Record<string, any>[];
  };
  
  
  const transformedData = transformWeekDayAndTimeData(data.WeekDayandTimeReport);

const [colorByMonth, setColorByMonth] = useState<Record<string, string>>({});
const [colorByWeek, setColorByWeek] = useState<Record<string, string>>({});
const [colorByTable, setColorByTable] = useState<Record<string, string>>({});
const [colorByFloor, setColorByFloor] = useState<Record<string, string>>({});

let months = data.MonthReport.map(ele => parseInt(ele.Month));
months = [...new Set(months)];  
months.sort((a, b) => a - b); 

let allMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

let dataByMonth = months.map(m => {
  let monthBi: any = {};
  monthBi.month = allMonths[m - 1];

  let filteredMonth = data.MonthReport.filter(ele => parseInt(ele.Month) === m);

  filteredMonth.forEach(ele => {
    monthBi[ele.Year] = ele.Total;
  });

  return monthBi;
});
useEffect(() => {
  const updateColors = () => {
    const weekColors: Record<string, string> = {};
    const monthColors: Record<string, string> = {};
    const tableColors: Record<string, string> = {};
    const floorColors: Record<string, string> = {};

    data.WeekReport.forEach(ele => {
      if (ele.Colour) {
        weekColors[ele.Year] = ele.Colour;
      }
    });

    if (selectedPeriod === "month") {
      data.MonthReport.forEach(ele => {
        if (ele.Colour) {
          monthColors[ele.Year] = ele.Colour;
        }
      });
    }

    data.TablesReport.forEach(ele => {
      if (ele.Colour) {
        tableColors[ele.Year] = ele.Colour;
      }
    });

    data.FloorsReport.forEach(ele => {
      if (ele.Colour) {
        floorColors[ele.Year] = ele.Colour;
      }
    });

    // Update the respective states
    setColorByWeek(weekColors);
    if (selectedPeriod === "month") setColorByMonth(monthColors);
    setColorByTable(tableColors);
    setColorByFloor(floorColors);
  };

  updateColors();
}, [data, selectedPeriod]);

let floorsReportData = data.FloorsReport.sort((a, b) => {
  if (a.Rbr < b.Rbr) return -1;
  if (a.Rbr > b.Rbr) return 1;
  return 0;
});

let years = data.MonthReport.map(ele => parseInt(ele.Year));
years = [...new Set(years)];
years.sort((a, b) => a - b);

let allDayWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const weekDaysAllYear = Array.from(
    new Set(data.WeekReport.map(ele => parseInt(ele.Year, 10)))
  ).sort((a, b) => a - b);

  const dataByWeekDay = allDayWeek.map((day, index) => {
    const weekDayBi: any = { day }; // Initialize with the day name
    data.WeekReport.forEach((ele) => {
      if (parseInt(ele.WeekDay, 10) === index + 1) {
        // Match WeekDay (1-based index)
        weekDayBi[ele.Year] = ele.Total;
      }
    });
    return weekDayBi;
  });

  const offscreenStyle: React.CSSProperties = {
    position: "absolute",
    top: "-10000px",
    left: "-10000px",
    width: "100%",
    height: "0",
    overflow: "hidden",
  };
  return (
    <Wrapper onClick={() => {}} classes={`w-[95%] mx-auto pt-2 md:pt-0 pb-16 min-h-screen flex `}>
        <BiReportFilters />
        
       
        <div className={`relative pt-[10rem] md:pt-[12rem] w-full `} style={{ maxHeight: "95dvh", overflowY: "auto" }}>
        <button
            onClick={exportToPDF}
            className="px-4 py-2 bg-custom-bgBlue text-white rounded-md"
          >
            Export to PDF
          </button>
        <div className="w-full grid md:grid-cols-2 gap-4 mb-4">
        <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-start">
          <p className="text-gray-500 text-sm">{t("biReport.activeTurnover")}</p>
          <p className="text-gray-700 text-sm">{new Date(data.Totals.ActiveDate).toLocaleDateString("en-GB")}</p>
          <p className="text-xl font-bold text-blue-600">{data.Totals.ActiveTurnover}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-start">
          <p className="text-gray-500 text-sm">{t("biReport.totalTurnover")}</p>
          <p className="text-gray-700 text-sm">{new Date(data.Totals.ActiveDate).toLocaleDateString("en-GB")}</p>
          <p className="text-xl font-bold text-green-600">{data.Totals.TotalTurnover}</p>
        </div>
      </div>

        <div className="grid md:grid-cols-2 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center" id="chart1" >
          <p className="text-2xl font-semibold mb-4">
            {t("biReport.totalTurnover")} {t(`biReport.${selectedPeriod}`)}
          </p>
        <div className=" w-full flex justify-center gap-2 mb-4" >
          <button
              onClick={() => setSelectedPeriod("month")}
              className={`px-4 py-2 rounded-lg capitalize text-sm font-medium ${
                selectedPeriod === "month"
                  ? "bg-custom-bgBlue text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              {t("biReport.month")}
            </button>
            <button
              onClick={() => setSelectedPeriod("year")}
              className={`px-4 py-2 rounded-lg capitalize text-sm font-medium ${
                selectedPeriod === "year"
                  ? "bg-custom-bgBlue text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              {t("biReport.year")}
            </button></div>
        {selectedPeriod === "month" ?
        <div className="w-full" >
          <SimpleBarChart 
          data={dataByMonth} 
          barKeys={years} 
          xKey="month" 
          colors={colorByMonth} 
          isMonthChart={true}/>
        </div>
          :
        <div className="w-full" >
          <SimpleBarChart 
          data={data.YearReport} 
          xKey="Year" 
          isYearChart={true}/>
        </div>
      }
    </div>


    <div className="w-full flex flex-col items-center bg-white rounded-lg shadow-md justify-center" id="chart2" >
          <p className="text-2xl font-semibold mb-16 ">
            {`${t("biReport.totalTurnover")} ${t(`biReport.week`)}`}
          </p>
          <div className="w-full" >
            <SimpleBarChart 
            data={dataByWeekDay} 
            barKeys={weekDaysAllYear} 
            xKey="weekday" 
            isWeekChart={true} 
            colors={colorByWeek}/>
          </div>
    </div>
 
    <div className="w-full flex flex-col items-center bg-white rounded-lg shadow-md justify-center" id="chart3">
          <p className="text-2xl font-semibold mb-4 pt-4">
            {`${t("biReport.turnoverPerHourPerDay")}`}
          </p>
          <div className="w-full" >
            <LineChartComponent 
            data={transformedData} />
          </div>
    </div>

    <div className="w-full flex flex-col items-center bg-white rounded-lg shadow-md justify-center" id="chart4">
          <p className="text-2xl font-semibold mb-4 pt-4">
            {`${t("biReport.topTableSales")}`}
          </p>
          <div className="w-full" >
            <SimpleBarChart 
            data={data.TablesReport} 
            barKeys={barKeys} 
            xKey="TableName" 
            isTableSalesChart={true} 
            colors={colorByTable}/>
          </div>
    </div>
   {data.FloorsReport.length > 0 ?
    <div className="w-full flex flex-col items-center bg-white rounded-lg shadow-md justify-center" id="chart5" >
          <p className="text-2xl font-semibold mb-4 pt-4">
            {`${t("biReport.turnoverPerFloor")}`}
          </p>
          <div className="w-full">
            <SimpleBarChart 
            data={floorsReportData} 
            barKeys={barKeys} 
            xKey="FloorName" 
            isFlorChart={true} 
            colors={colorByFloor}/>
          </div>
    </div>
     : <></>}
        </div>
        {/* for pdf */}
        <div style={offscreenStyle}
      >
         {selectedPeriod === "month" ?
        <div className="w-full" 
        ref={(el) => addChartRef(0, el)}>
          <SimpleBarChart 
          data={dataByMonth} 
          barKeys={years} 
          xKey="month" 
          colors={colorByMonth} 
          isMonthChart={true}/>
        </div>
        :
        <div className="w-full" 
        ref={(el) => addChartRef(0, el)}>
          <SimpleBarChart 
          data={data.YearReport} 
          xKey="Year" 
          isYearChart={true}/>
        </div>
      }

        <div className="w-full" 
        ref={(el) => addChartRef(1, el)}>
          <SimpleBarChart 
          data={dataByWeekDay} 
          barKeys={weekDaysAllYear} 
          xKey="weekday" 
          isWeekChart={true} 
          colors={colorByWeek}/>
        </div>

        <div className="w-full" 
        ref={(el) => addChartRef(2, el)}>
          <LineChartComponent 
          data={transformedData}/>
       </div>

       <div className="w-full" 
       ref={(el) => addChartRef(3, el)}>
          <SimpleBarChart 
          data={data.TablesReport} 
          barKeys={barKeys} 
          xKey="TableName" 
          isTableSalesChart={true} 
          colors={colorByTable}/>
      </div>

        <div className="w-full" 
        ref={(el) => addChartRef(4, el)}>
          <SimpleBarChart 
          data={floorsReportData} 
          barKeys={barKeys} 
          xKey="FloorName" 
          isFlorChart={true} 
          colors={colorByFloor}/>
        </div>
        </div>
       
        </div>
      <ScrollToTopButton />
    </Wrapper>
  );
};

export default BiReport;


